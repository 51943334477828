function Email() {
  return (
    <div>
      
      <div class="fixed-action-btn">
        <a class="btn-floating btn-large red " href="mailto: contact@cureco.ai">
          <i class="large material-icons">email</i>
        </a>
      </div>

    </div>
    
    
  );
}

export default Email;
