import Home from './Home';
import Email from './Email';
// import Footer from './Footer';


function App() {
  return (
    <div className="App">
      
      <Home />
      <Email />
      {/* <Footer /> */}
    </div>
  );
}

export default App;
